import React from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import { withRouter } from 'next/router';
import amplitude from 'amplitude-js';
import { capitalize, getUrlParam } from '../../helpers';
import {
  get30Days,
  getXHoursFromNow,
  getXDayFromNow,
  dateToDateStr,
} from '../../../helpers/date-helpers';
import Steps from './Steps';

export const getFields = (data, fields, touched, steps, dependencies) => {
  const cookies = new Cookies();
  let children = data.innerBlocks || [];

  for (let i = 0; i < children.length; i++) {
    let child = children[i];

    if (/multistepForm_step/.test(child.attributes.className)) {
      let dependency = child.attributes.data.dependency;

      if (dependency) {
        dependencies.push(dependency);
      } else {
        dependencies.push(null);
      }

      steps.push([]);
    }

    if (
      child.name === 'field' ||
      child.name === 'select' ||
      child.name === 'radios'
    ) {
      let cookieValue = cookies.get(child.attributes.name);

      if (cookieValue) {
        fields[child.attributes.name] = cookieValue;
        touched[child.attributes.name] = true;
      } else {
        fields[child.attributes.name] = '';
      }

      let step = steps[steps.length - 1];
      if (step) {
        step.push(child.attributes.name);
      }
    }

    getFields(child, fields, touched, steps, dependencies);
  }

  return [fields, touched, steps, dependencies];
};

export const validate = (values, step) => {
  let errors = {},
    valuesToCheck = {};

  if (step) {
    for (let i = 0; i < step.length; i++) {
      let field = step[i];
      valuesToCheck[field] = values[field];
    }
  } else {
    valuesToCheck = values;
  }

  for (const key in valuesToCheck) {
    if (values.hasOwnProperty(key)) {
      let val = values[key];

      switch (key) {
        case 'user_name':
          if (!val || val.length <= 0) {
            errors.user_name = 'Invalid user name';
          }
          break;
        case 'first_name':
          if (!val || val.length <= 0) {
            errors.first_name = 'Invalid first name';
          }
          break;
        case 'last_name':
          if (!val || val.length <= 0) {
            errors.last_name = 'Invalid last name';
          }
          break;
        case 'user_email':
          let emailRegex = /^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;

          if (!emailRegex.test(val)) {
            errors.user_email = 'Invalid user email';
          }
          break;
        case 'user_company':
          if (!val || val.length <= 0) {
            errors.user_company = 'Invalid user company';
          }
          break;
        case 'user_employees':
          val = parseInt(val);
          if (!val || val <= 0 || isNaN(val)) {
            errors.user_employees = 'Invalid user employees';
          }
          break;
        case 'user_lead_source':
          if (!val || val === '') {
            errors.user_lead_source = 'Invalid lead source';
          }
          break;
        case 'contact_reason':
          if (!val || val === '') {
            errors.contact_reason = 'Invalid contact reason';
          }
          break;
        case 'company_offer_insurance':
          if (!val || val === '') {
            errors.company_offer_insurance =
              'Invalid company issurance offer answer';
          }
          break;
        case 'insurance_date':
          if (valuesToCheck.company_offer_insurance !== 'Yes') {
            break;
          }

          // For Safari and browsers that don't support date field
          if (/\//.test(val)) {
            let data = val.split('/');
            var [month, date, year] = data;
          } else {
            let data = val.split('-');
            var [year, month, date] = data;
          }

          let now = new Date(),
            currentYear = now.getFullYear(),
            currentMonth = now.getMonth() + 1,
            currentDate = now.getDate();

          year = parseInt(year);
          month = parseInt(month);
          date = parseInt(date);

          if (!year || !month || !date) {
            errors.insurance_date = 'Invalid date. Expected format: mm/dd/yyyy';
          } else if (
            year < currentYear ||
            (year === currentYear && month < currentMonth) ||
            (year === currentYear &&
              month === currentMonth &&
              date <= currentDate)
          ) {
            errors.insurance_date = 'Date already passed';
          } else if (year > 2100) {
            errors.insurance_date = 'Date not covered';
          }

          break;
        case 'user_previous_payroll':
          let userEmployees = valuesToCheck.user_employees
            ? parseInt(valuesToCheck.user_employees)
            : 0;
          if ((!val || val === '') && userEmployees <= 19) {
            errors.user_previous_payroll = 'Invalid previous payroll answer';
          }
          break;
        case 'user_phone':
          if (
            !val ||
            val === '' ||
            (val.length < 14 && val[0] === '(') ||
            (val.length !== 10 && val[0] !== '(')
          ) {
            errors.user_phone = 'Invalid phone number';
          }
          break;
        case 'schedule_demo_datetime':
          if (!val || val.length <= 0) {
            errors.schedule_demo_datetime = 'Please select a date';
          } else if (val.getHours() < 6 || val.getHours() > 17) {
            errors.schedule_demo_datetime =
              'Please select a time between 6am and 5pm';
          }
          break;
        default:
      }
    }
  }

  return errors;
};

// TO DO: Deb's function
export function safelyParseJSON(json) {
  var parsed;

  try {
    parsed = JSON.parse(json);
  } catch (e) {
    // Oh well, but whatever...
  }
  return parsed;
}

export const googleAdwordsTracking = () => {
  if (window.google_trackConversion) {
    window.google_trackConversion({
      google_conversion_id: 794594069,
      google_conversion_language: 'en',
      google_conversion_format: '3',
      google_conversion_color: 'ffffff',
      google_conversion_label: 'odM0CJb_sIkBEJWW8voC',
      google_remarketing_only: false,
    });
  }
};

export const firePPCSitePixels = () => {
  // trigger capterra tracking.
  var capterra_vkey = '5d388caf8dac5a4cabc7669880b61e59',
    capterra_vid = '2119234',
    capterra_prefix =
      'https:' === document.location.protocol
        ? 'https://ct.capterra.com'
        : 'http://ct.capterra.com';

  (function () {
    var ct = document.createElement('script');
    ct.type = 'text/javascript';
    ct.async = true;
    ct.src =
      capterra_prefix +
      '/capterra_tracker.js?vid=' +
      capterra_vid +
      '&vkey=' +
      capterra_vkey;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ct, s);
  })();

  // trigger crozdesk tracking.
  (function () {
    var cdx = document.createElement('script');
    cdx.type = 'text/javascript';
    cdx.async = true;
    cdx.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'trk.crozdesk.com/FC4ZLTASMywvbywebrSQ';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(cdx, s);
  })();
};

export const getOptimizelyFields = () => {
  window['optimizely'] = window['optimizely'] || [];
  let fields = {
    experimentIds: '',
    variationIds: '',
    audienceIds: '',
  };

  if (window.optimizely.get) {
    let experimentStates = window.optimizely.get('state').getExperimentStates();
    let pageStates = window.optimizely.get('state').getPageStates();

    for (let key in experimentStates) {
      if (experimentStates.hasOwnProperty(key)) {
        let experimentState = experimentStates[key];
        fields.experimentIds = fields.experimentIds
          ? `${fields.experimentIds}, ${key}`
          : key;

        let variationId = experimentState.variation?.id;
        fields.variationIds = fields.variationIds
          ? `${fields.variationIds}, ${variationId}`
          : variationId;

        let audienceId = experimentState.audiences?.[0]?.id;
        fields.audienceIds = fields.audienceIds
          ? `${fields.audienceIds}, ${audienceId}`
          : audienceId;
      }
    }

    for (let key in pageStates) {
      if (pageStates.hasOwnProperty(key)) {
        let pageState = pageStates[key];
        if (pageState.isActive) {
          fields.pageId = key;
          break;
        }
      }
    }
  }

  return fields;
};

let complete = false;

export const onError = (e) => {
  alert(
    'The form submission failed. Please refresh the page and try again or contact someone at resources@rippling.com'
  );
};

export const submissionHandler = async (
  values,
  router,
  customOnSuccess,
  turnOffThankYouPageRedirect,
  redirectTo,
  setIsComplete
) => {
  googleAdwordsTracking();
  firePPCSitePixels();
  const cookies = new Cookies();

  let {
    first_name,
    last_name,
    user_name,
    user_email,
    user_company,
    user_employees,
    user_phone,
    user_lead_source,
    who_referred_you,
    user_previous_payroll,
    company_offer_insurance,
    insurance_date,
    schedule_demo_datetime,
    schedule_demo_timezone,
    contact_reason,
    ur_products,
    ur_topics,
  } = values;

  let hiddenFields = {
    referrer: document.querySelector('input[name="referrer"]'),
    initial_landing_page: document.querySelector(
      'input[name="initial_landing_page"]'
    ),
    form_category: document.querySelector('input[name="Form_Category"]'),
    form_type: document.querySelector('input[name="Form_Type__c"]'),
    user_company_type: document.querySelector('input[name="company_type"]'),
    video_tour: document.querySelector('input[name="video_tour"]'),
    p_name: document.querySelector('input[name="p_name"]'),
    Last_Lead_Origin__c: document.querySelector(
      'input[name="Last_Lead_Origin__c"]'
    ),
    Product_Updates_Opt_Out__c: document.querySelector(
      'input[name="Product_Updates_Opt_Out__c"]'
    ),
    Webinar_Registration_Date__c: document.querySelector(
      'input[name="eventDate"]'
    ),
  };

  let schedule_demo_date, schedule_demo_time;

  if (schedule_demo_datetime) {
    const zeroify = (num) => ('0' + num).slice(-2);

    [schedule_demo_date, schedule_demo_time] = [
      `${zeroify(schedule_demo_datetime.getMonth() + 1)}/${zeroify(
        schedule_demo_datetime.getDate()
      )}/${schedule_demo_datetime.getFullYear()}`,
      `${zeroify(schedule_demo_datetime.getHours())}:${zeroify(
        schedule_demo_datetime.getMinutes()
      )}`,
    ];
  }

  try {
    var savedFormData = cookies.get('saved_form_values');

    var formData = {
      first_name: first_name && first_name.trim(),
      last_name: last_name && last_name.trim(),
      user_name: user_name && user_name.trim(),
      user_email: user_email && user_email.trim(),
      user_company: user_company && user_company.trim(),
      user_employees: user_employees && parseInt(user_employees),
      user_lead_source: user_lead_source && user_lead_source.trim(),
      who_referred_you: who_referred_you && who_referred_you.trim(),
      user_phone: user_phone && user_phone.trim(),
      contact_reason: contact_reason && contact_reason.trim(),
      schedule_demo_date,
      schedule_demo_time,
      schedule_demo_timezone,
      ur_products: ur_products?.join(';'),
      ur_topics: ur_topics?.join(';'),
      unityWebinarName: values.unityWebinarName?.join(';'),
      Offer_Health_Insurance__c:
        company_offer_insurance && company_offer_insurance.trim(),
      Medical_Renewal_Date__c_lead: insurance_date && insurance_date.trim(),
      user_previous_payroll:
        user_previous_payroll && user_previous_payroll.trim(),
      user_company_type: hiddenFields.user_company_type
        ? hiddenFields.user_company_type.value
        : undefined,
      video_tour: hiddenFields.video_tour
        ? hiddenFields.video_tour.value
        : undefined,
      p_name: hiddenFields.p_name ? hiddenFields.p_name.value : undefined,
      Last_Lead_Origin__c: hiddenFields.Last_Lead_Origin__c
        ? hiddenFields.Last_Lead_Origin__c.value
        : undefined,
      form_type: hiddenFields.form_type
        ? hiddenFields.form_type.value
        : undefined,
      referrer: hiddenFields.referrer ? hiddenFields.referrer.value : undefined,
      initial_landing_page: hiddenFields.initial_landing_page
        ? hiddenFields.initial_landing_page.value
        : undefined,
      experiment_id: hiddenFields.experiment_id
        ? hiddenFields.experiment_id.value
        : undefined,
      variation_id: hiddenFields.variation_id
        ? hiddenFields.variation_id.value
        : undefined,
      audience_id: hiddenFields.audience_id
        ? hiddenFields.audience_id.value
        : undefined,
      event_id: hiddenFields.event_id ? hiddenFields.event_id.value : undefined,
      page_id: hiddenFields.page_id ? hiddenFields.page_id.value : undefined,
      Product_Updates_Opt_Out__c: hiddenFields.Product_Updates_Opt_Out__c
        ? hiddenFields.Product_Updates_Opt_Out__c.value
        : undefined,
      Webinar_Registration_Date__c: hiddenFields.Webinar_Registration_Date__c
        ? dateToDateStr(hiddenFields.Webinar_Registration_Date__c.value)
        : undefined,
    };

    // Forms that update existing records need all previous values
    if (
      ['/thank-you', '/resources/peo-quiz'].includes(window.location.pathname)
    ) {
      if (savedFormData) {
        try {
          if (typeof savedFormData === 'object') {
            for (const key in formData) {
              const val = formData[key];
              const savedVal = savedFormData[key];

              if (!val && savedVal) {
                formData[key] = savedVal;
              }
            }
          }
        } catch (e) {
          console.log('e: ', e);
        }
      }
    }
  } catch (e) {
    onError();
  }

  let quiz_redirect;

  if (window.location.pathname === '/resources/peo-quiz') {
    const user_employees =
      formData.user_employees && parseInt(formData.user_employees);

    if (user_employees >= 100) {
      quiz_redirect = '2b';
    } else if (user_employees <= 4) {
      if (
        values.w2EmployeesNumOfStates === 'Yes' ||
        [
          'We-plan-to-hire-employees-in-more-than-one-state',
          'We-plan-to-hire-employees-only-in-our-HQ',
        ].includes(values.employeesGrowth6Months)
      ) {
        quiz_redirect = '1a';
      } else {
        quiz_redirect = '2a';
      }
    } else if (user_employees > 4 && user_employees <= 100) {
      if (values.employeesGrowth6Months === 'We-plan-to-downsize') {
        quiz_redirect = '2a';
      } else {
        quiz_redirect = '1a';
      }
    }
  }

  const hostname = window.location.hostname;
  let existingRipplingCookieExperiments =
    cookies.get('rippling_experiments') || [];

  try {
    var fullName = formData.user_name
        ? formData.user_name.split(' ')
        : [formData.first_name, formData.last_name],
      refercookieInfo = safelyParseJSON(cookies.get('rippling_refer_user')),
      gclidInfo = cookies.get('gclid');
  } catch (e) {
    onError();
  }

  var bodyFormData = new FormData();

  const addToFormData = (key, value) => {
    if (value !== undefined) {
      bodyFormData.append(key, value);
    }
  };

  let optimizelyFields = getOptimizelyFields();
  let form_category = hiddenFields.form_category?.value;
  let isQuote = form_category === 'quote';

  addToFormData('Email', formData.user_email);
  addToFormData(
    'FirstName',
    fullName.length > 1 ? fullName.slice(0, -1).join(' ') : fullName[0]
  );
  addToFormData(
    'LastName',
    fullName.length > 1 ? fullName.slice(-1).join(' ') : '[[unknown]]'
  );
  addToFormData('Company', formData.user_company);
  addToFormData('NumberOfEmployees', formData.user_employees);
  addToFormData('ReferrerSource', formData.user_lead_source);
  addToFormData('Referring_Partner__c', formData.who_referred_you);
  addToFormData('LT_Campaign__c', cookies.get('utm_campaign'));
  addToFormData('LT_Content__c', cookies.get('utm_content'));
  addToFormData('LT_Medium__c', cookies.get('utm_medium'));
  addToFormData('LT_Source__c', cookies.get('utm_source'));
  addToFormData('LT_Term__c', cookies.get('utm_term'));
  addToFormData(
    'Ambassador__Short_Code__c',
    refercookieInfo ? refercookieInfo.short_code : ''
  );
  addToFormData('GCLID__c', gclidInfo ? gclidInfo : '');
  addToFormData('Has_Payroll__c', formData.user_previous_payroll || 'False');
  addToFormData('request_quote', isQuote ? isQuote : '');
  addToFormData('Phone', formData.user_phone);
  addToFormData('Company_Type_Detail__c', formData.user_company_type);
  addToFormData(
    'Offer_Health_Insurance__c',
    formData.Offer_Health_Insurance__c
  );
  addToFormData(
    'Medical_Renewal_Date__c_lead',
    formData.Medical_Renewal_Date__c_lead
  );
  addToFormData('video_tour', formData.video_tour);
  addToFormData('utilityBetaandResearchTopicText', formData.ur_topics);
  addToFormData('utilityBetaandResearchProductTextArea', formData.ur_products);
  addToFormData('unityWebinarName', formData.unityWebinarName);
  addToFormData(
    'utilityGiftCardIncentive',
    cookies.get('homepage_100dollar_exit_popup_seen')
  );
  addToFormData(
    'W2_employees_in_more_than_one_state__c',
    values.w2EmployeesNumOfStates
  );
  addToFormData(
    'Next_6_months_for_employee_growth__c',
    values.employeesGrowth6Months
  );
  addToFormData(
    'Offer_employees_medical_insurance__c',
    values.offerMedicalInsurance
  );
  addToFormData(
    'How_compliance_for_HR_is_handled__c',
    values.ensureComplianceWithPayrollLaws
  );
  addToFormData('Marketo_PEO_Quiz_Result__c', quiz_redirect);
  addToFormData('p_name', formData.p_name);
  addToFormData('Contact_Us_Reason__c', formData.contact_reason);
  addToFormData('Demo_Request_Meeting_Date__c', formData.schedule_demo_date);
  addToFormData('Demo_Request_Meeting_Time__c', formData.schedule_demo_time);
  addToFormData(
    'Demo_Request_Meeting_Time_Zone__c',
    formData.schedule_demo_timezone
  );
  addToFormData('Last_Lead_Origin__c', formData.Last_Lead_Origin__c);
  addToFormData('Optimizely_Experiment_ID__c', optimizelyFields.experimentIds);
  addToFormData('Optimizely_Variation_ID__c', optimizelyFields.variationIds);
  addToFormData('Optimizely_Audience_ID__c', optimizelyFields.audienceIds);
  addToFormData('Optimizely_Page_ID__c', optimizelyFields.pageId);
  addToFormData('Form_Type__c', formData.form_type);
  addToFormData('referrer_url__c', formData.referrer);
  addToFormData('Landing_Page__c', formData.initial_landing_page);
  addToFormData(
    'Product_Updates_Opt_Out__c',
    formData.Product_Updates_Opt_Out__c
  );
  addToFormData(
    'Webinar_Registration_Date__c',
    formData.Webinar_Registration_Date__c
  );

  try {
    let activeIncompleteExperiments = existingRipplingCookieExperiments.filter(
      (exp) => !exp.complete
    );

    if (activeIncompleteExperiments.length) {
      let lastExperiment =
        activeIncompleteExperiments[activeIncompleteExperiments.length - 1];

      addToFormData('Rippling_Experiment', lastExperiment.name);
      addToFormData('Rippling_Variation', lastExperiment.assignment);
    }
  } catch (e) {
    console.log('e: ', e);
  }

  await axios({
    method: 'post',
    url: 'https://app.rippling.com/api/growth/request_demo',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(() => {
      complete = true;
      if (setIsComplete) {
        setIsComplete();
      }

      if (amplitude && amplitude.getInstance) {
        let eventProperties = {};
        eventProperties['Name'] =
          fullName.length > 1 ? fullName.slice(0, -1).join(' ') : fullName[0];
        if (formData.user_lead_source) {
          eventProperties['HDYHAU'] = formData.user_lead_source;
        }
        if (formData.user_company) {
          eventProperties['Company'] = formData.user_company;
        }
        if (formData.user_email) {
          eventProperties['Email'] = formData.user_email;
        }
        if (formData.user_phone) {
          eventProperties['Phone'] = formData.user_phone;
        }
        if (formData.user_employees) {
          eventProperties['Number of Employees'] = formData.user_employees;
          eventProperties['Market Segment'] =
            formData.user_employees > 25 ? 'MM' : 'SMB';
        }
        if (formData.Last_Lead_Origin__c) {
          eventProperties['Last Lead Origin'] = formData.Last_Lead_Origin__c;
        }
        if (formData.form_type) {
          eventProperties['Form Type'] = formData.form_type;
        }
        if (optimizelyFields.experimentIds) {
          eventProperties['Optimizely_Experiment_ID__c'] =
            optimizelyFields.experimentIds;
        }
        if (optimizelyFields.variationIds) {
          eventProperties['Optimizely_Variation_ID__c'] =
            optimizelyFields.variationIds;
        }
        if (optimizelyFields.audienceIds) {
          eventProperties['Optimizely_Audience_ID__c'] =
            optimizelyFields.audienceIds;
        }
        if (optimizelyFields.pageId) {
          eventProperties['Optimizely_Page_ID__c'] = optimizelyFields.pageId;
        }

        // Experiment submission
        let activeIncompleteExperiments = existingRipplingCookieExperiments.filter(
          (exp) => !exp.complete
        );

        // Should only ever run once ATM
        activeIncompleteExperiments.forEach((experiment) => {
          experiment.complete = true;

          cookies.set(
            'rippling_experiments',
            existingRipplingCookieExperiments,
            { path: '/', domain: hostname, expires: getXDayFromNow(30) }
          );

          eventProperties['Rippling Experiment'] = experiment.name;
          eventProperties['Rippling Variation'] = experiment.assignment;
          console.log(
            `%c${experiment.name} (${experiment.assignment})%c converted`,
            'font-weight: bold; color: blue',
            'color: blue'
          );
        });

        if (
          !/@testrippling.com$/.test(formData.user_email) &&
          window.location.hostname === 'www.rippling.com'
        ) {
          amplitude
            .getInstance()
            .logEvent(
              `Form Submit - ${capitalize(form_category)}`,
              eventProperties
            );
        }
      }

      if (
        !/@testrippling.com$/.test(formData.user_email) &&
        window.location.hostname === 'www.rippling.com'
      ) {
        window['optimizely'].push({
          type: 'user',
          attributes: {
            userEmail: formData.user_email,
          },
        });

        window['optimizely'].push({
          type: 'event',
          eventName: 'form_fill',
          tags: {
            email: formData.user_email,
          },
        });

        let secondaryEventName = '';

        if (form_category === 'demo' || form_category === 'partner') {
          secondaryEventName = 'demo_request';
        } else if (form_category === 'quote') {
          secondaryEventName = 'quote_request';
        } else if (
          form_category === 'tour' &&
          window.location.pathname === '/interactive-tour'
        ) {
          secondaryEventName = 'interactive_tour_request';
        } else if (form_category === 'tour') {
          secondaryEventName = 'tour_request';
        } else if (form_category === 'content') {
          secondaryEventName = 'content_request';
        }

        window['optimizely'].push({
          type: 'event',
          eventName: secondaryEventName,
        });
      }

      window.dataLayer.push({
        event: 'Form Submit (Success)',
        formCompanyName: formData.user_company,
        formCategory: form_category,
        formType: formData.form_type,
        formFullName: fullName,
        formLeadSource: formData.user_lead_source,
        formNumberOfEmployees: formData.user_employees,
        formWorkEmail: formData.user_email,
        formWorkPhone: formData.user_phone,
      });

      if (customOnSuccess) {
        customOnSuccess();
      }

      try {
        if (window.clearbit && window.clearbit.identify) {
          window.clearbit.identify(formData.user_email, {
            email: formData.user_email,
          });
        }
      } catch (e) {
        console.log('e: ', e);
      }

      const cookies = new Cookies();
      cookies.set('thankyou_email', formData.user_email, {
        path: '/',
        domain: window.location.hostname,
        expires: get30Days(),
      });

      if (formData.schedule_demo_date) {
        if (window.location.pathname === '/thank-you') {
          const scheduleMessage = document.querySelector('.scheduleMessage');
          const formStep = document.querySelector('form .multistepForm_step');

          formStep.classList.add('-hide');

          if (scheduleMessage) {
            let [hours, minutes] = formData.schedule_demo_time.split(':');
            let meridiem = 'am';

            if (hours >= 12) {
              meridiem = 'pm';

              if (hours > 12) {
                hours = hours > 12 ? hours - 12 : hours;
              }
            }

            scheduleMessage.innerText = `You are confirmed for: ${formData.schedule_demo_date} @ ${hours}:${minutes}${meridiem} ${formData.schedule_demo_timezone}`;
          }

          scheduleMessage.classList.remove('hidden');
        }
      }

      const isStorylaneForm = router.asPath === '/interactive-tour';
      if (isStorylaneForm) {
        amplitude.getInstance().logEvent(`Form Submit - Interactive Tour`);

        // eslint-disable-next-line no-undef
        launchStorylaneDemo();
      } else if (!turnOffThankYouPageRedirect) {
        if (quiz_redirect) {
          quiz_redirect = quiz_redirect === '1a' ? '1' : quiz_redirect;

          router.push(`/hr-quiz-results-${quiz_redirect}`);
        } else {
          cookies.set('saved_form_values', formData, {
            path: '/',
            domain: hostname,
            expires: getXHoursFromNow(48),
          });
          router.push(redirectTo || '/thank-you');
        }
      }
    })
    .catch((e) => {
      console.log('e: ', e);
      if (!complete) {
        onError(e);
      }
    });
};

export const handleIsPartnerPageListing = (fields, touched) => {
  let isPartnerPageListing =
    getUrlParam('utm_campaign') === 'partner_page_listing';

  if (isPartnerPageListing) {
    let utmMedium = getUrlParam('utm_medium');
    let utmSource = getUrlParam('utm_source');

    if (utmMedium) {
      let userLeadSource = {
        accountant: 'Accountant',
        broker: 'Insurance Broker',
      };

      fields['user_lead_source'] = userLeadSource[utmMedium];
      touched['user_lead_source'] = true;
    }

    if (utmSource) {
      let whoReferredYou = {
        basis365: 'Basis 365',
        'aduro-advisors': 'Aduro Advisors',
        aeis: 'AEIS Advisors',
        akw: 'AKW Financial',
        alium: 'Alium',
        alliant: 'Alliant',
        bdo: 'BDO',
        bookkeeper360: 'Bookkeeper360',
        cfa: 'CFA Insurance',
        countsy: 'Countsy',
        finvisor: 'Finvisor',
        formationfinancial: 'Formation Financial',
        'kbi-benefits': 'KBI Consulting',
        'kruze-consulting': 'Kruze Consulting',
        launchways: 'Launchways',
        rfp: 'RFP',
        guideline: '',
        capitolbenefits: 'Capitol Benefits',
      };

      fields['who_referred_you'] = whoReferredYou[utmSource] || utmSource;
      touched['who_referred_you'] = true;
    }
  }
};

export function disableEnterSubmitOnKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

class BasicForm extends React.Component {
  constructor(props) {
    super(props);

    let [fields, touched, steps, dependencies] = getFields(
      this.props,
      {},
      {},
      [],
      []
    );
    this.fields = fields;
    this.touched = touched;
    this.steps = steps;
    this.dependencies = dependencies;

    let hiddenFields = this.props.attributes.hiddenInputs;
    this.hiddenFields = [];
    this.referralField = null;
    this.formRef = React.createRef();

    for (let key in hiddenFields) {
      let value = hiddenFields[key];
      this.hiddenFields.push(<input type="hidden" name={key} value={value} />);
    }
  }

  componentDidMount() {
    handleIsPartnerPageListing(this.fields, this.touched);
  }

  componentWillUnmount() {
    complete = false;
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    let { attributes, children } = this.props;
    let {
      className,
      data,
      customOnSuccess,
      formType,
      disableEnterSubmit,
      turnOffThankYouPageRedirect,
      redirectTo,
    } = attributes;
    const cookies = new Cookies();
    const initialLandingPage = cookies.get('initial_landing_page');

    if (typeof window !== 'undefined' && window?.document?.referrer) {
      this.referralField = (
        <input name="referrer" type="hidden" value={window.document.referrer} />
      );
    }

    return (
      <Formik
        initialValues={this.fields}
        initialTouched={this.touched}
        validateOnChange={true}
        validate={validate}
        onSubmit={(values) =>
          submissionHandler(
            values,
            this.props.router,
            customOnSuccess,
            turnOffThankYouPageRedirect,
            redirectTo
          )
        }
      >
        {({ errors, touched, setValues, submitForm, values }) => {
          let child = children[0];

          return (
            <form
              className={`form ${classnames({
                [className]: className,
                '-error': Object.keys(errors).length,
              })}`}
              ref={this.formRef}
              {...data}
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
              onKeyDown={
                disableEnterSubmit ? disableEnterSubmitOnKeyDown : undefined
              }
            >
              <input name="Form_Category" type="hidden" value={formType} />
              {this.hiddenFields}
              {this.referralField}
              {initialLandingPage && (
                <input
                  name="initial_landing_page"
                  type="hidden"
                  value={initialLandingPage}
                />
              )}
              <Steps
                {...child.props}
                values={values}
                errors={errors}
                touched={touched}
                formRef={this.formRef}
                complete={complete}
                validate={validate}
                setValues={setValues}
                steps={this.steps}
                dependencies={this.dependencies}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default withRouter(BasicForm);

import React from 'react';
import Link from '../../templates/partials/LinkHelper';
import Image from '../basic/Image';

const GlossaryGenericCta = (props) => {
  const { attributes } = props;
  let {
    iconUrl,
    headline,
    buttonText,
    buttonUrl
  } = attributes;


  iconUrl = iconUrl ? iconUrl : 'https://rippling.imgix.net/images/blog-inline-generic-cta-btn.svg';
  headline = headline ? headline : 'Schedule a demo with Rippling today'
  buttonText = buttonText ? buttonText : 'See Rippling';
  buttonUrl = buttonUrl ? buttonUrl : '/request-demo';
  return (
    <div className="wrapper pb-24 md:pb-48 pt-24 md:pt-48 sm:max-w-725  md:px-0 w-100%">
         <Link
            href={buttonUrl}
            className="block wrapper group bg-eggshell flex flex-col items-center justify-center mx-auto overflow-hidden py-48 rounded-4 text-center w-100% sm:max-w-750 sm:text-left
            no-underline"
        >
            <div className="imagex mb-24 h-48 w-48">
                <Image
                    alt=""
                    attributes={{ mediaURL: iconUrl, width: 48, height: 48 }}
                />
            </div>
            <p className="font-semibold leading-26 max-w-300 mb-8 pb-24 text-22 text-center text-charcoal sm:leading-30 sm:text-24 md:max-w-500">
                {headline}
            </p> 
            <div
                className="btn  btn bg-yellow leading-22 min-w-150 px-30 py-10 rounded-2 text-15"
                >
                    <span>
                        {buttonText}
                    </span>
                 </div>
        </Link>
    </div>
  );
}

export default GlossaryGenericCta;
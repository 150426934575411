import React, { useEffect } from 'react';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import { useRouter } from 'next/router';
import LinkHelper from '../../templates/partials/LinkHelper';

const Wrapper = (props) => {
  const router = useRouter();
  const { attributes, children } = props;
  let {
    alias,
    className,
    data,
    inlineHTML,
    link,
    simulateClick,
    openInNewTab,
    onMount,
  } = attributes;

  useEffect(() => {
    if (onMount) {
      const cookies = new Cookies();

      new Function('values', onMount)({ cookies, router });
    }
  }, []);

  const baseClassName = [
    'Row',
    'Grid',
    'Aligner',
    'Column',
    'ImageX',
    'VideoX',
    'IconX',
  ].includes(alias)
    ? alias.toLowerCase()
    : 'wrapper';

  const fullClassName = `${className} ${classnames({ 'no-underline': link })}`;
  className = `${baseClassName} ${fullClassName}`;
  const anchorProps = {
    className: `block ${baseClassName} ${fullClassName}`,
    target: openInNewTab && '_blank',
    rel: openInNewTab && 'noopener noreferrer',
  };

  const getOuterElement = () => {
    if (link) {
      return (
        <LinkHelper
          href={link}
          simulateClick={simulateClick}
          anchorProps={anchorProps}
          data={data}
        >
          {inlineHTML ? (
            <div dangerouslySetInnerHTML={{ __html: inlineHTML }} />
          ) : undefined}
          {children}
        </LinkHelper>
      );
    } else {
      return (
        <div className={className} {...data}>
          {inlineHTML ? (
            <div dangerouslySetInnerHTML={{ __html: inlineHTML }} />
          ) : undefined}
          {children}
        </div>
      );
    }
  };

  return getOuterElement();
};

export default Wrapper;

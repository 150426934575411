import React from 'react';

const GlossaryDisclaimer = (props) => {
  const { attributes } = props;
  let {
    copy
  } = attributes;



  return (
    <div className="mb-24">
      <div class="grid sm:max-w-725 w-100%">
        <div class="row">
          <div class="column flex flex-col items-start w-100%">
            <p class="is-style-p2 font-italic">
              <i 
                dangerouslySetInnerHTML={{ __html: copy }}
              >
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlossaryDisclaimer;
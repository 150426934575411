import React from 'react';
import { Field } from 'formik';

class Radios extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: null, children: null };
  }

  render() {
    let {
      attributes,
      setValues,
      handleChange,
      values,
      isActive,
      renderedAsFormButtons,
    } = this.props;
    let {
      className,
      innerClass,
      radioClass,
      labelClass,
      data,
      name,
      opts,
      selectMultiple,
    } = attributes;

    let optKeys = opts && Object.keys(opts);
    values[name] = values[name] || [];

    let result = (optKeys || []).map((option, i) => {
      var label = option;
      var value = opts[option];
      var checked;

      if (selectMultiple) {
        checked = values[name].includes(value) ? true : false;
      } else {
        checked = values[name] === value + '' ? true : false;
      }

      const id =
        typeof parseInt(value) === 'number' ? `id${value}${Date.now()}` : value;
      // the below was added to support radios as form buttons, so that the first always appears to be the "default"/preferred option
      const nothingCheckedYet = values[name].length === 0;
      const isFirstOption = i === 0;
      const shouldShowAsDefaultButton =
        renderedAsFormButtons && isFirstOption && nothingCheckedYet;
      const showAsChecked = shouldShowAsDefaultButton || checked;

      return (
        <div className={`${innerClass}${showAsChecked ? ' checked' : ''}`}>
          <Field
            type={selectMultiple ? 'checkbox' : 'radio'}
            tabIndex={!isActive ? -1 : undefined}
            className={radioClass}
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={(e) => {
              // Note: Since radio buttons remain focused after selection,
              // we need to manually blur them to immediately update .touched
              // (including the first click)
              e.currentTarget.blur();
              handleChange(e);
            }}
          />

          <label htmlFor={id} className={`${labelClass} radio mb-12`} key={i}>
            <span dangerouslySetInnerHTML={{ __html: label }} />
          </label>
        </div>
      );
    });

    return <div className={`${className}`}>{result}</div>;
  }
}

export default Radios;

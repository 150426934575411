/**
 * Clearbit Person API (email lookup) Proxy: https://dashboard.clearbit.com/docs#enrichment-api-person-api-email-lookup
 */
import { CLEARBIT_API_KEY } from ':constants/env';
import { fetchAndParseWithRetry } from ':helpers/network-helpers';
import { isNonEmptyObject } from ':helpers/validation-helpers';

type ClearbitUserRecord = {
  id: string;
  email: string;
  name: {
    fullName: string;
    givenName: string;
    familyName: string;
  };
  phone: string;
  location: string;
  timeZone: string;
  utcOffset: number;
  emailProvider: boolean;
  geo: {
    city: string;
    state: string;
    stateCode: string;
    country: string;
    countryCode: string;
    lat: number;
    lng: number;
  };
  bio: null;
  site: string;
  employment: {
    domain: string;
    name: string;
    title: string;
    role: string;
    subRole: string;
    seniority: string;
  };
  facebook: {};
  github: {};
  twitter: {};
  linkedin: {};
  googleplus: {};
  gravatar: {};
  fuzzy: boolean;
  indexedAt: string;
  activeAt: string;
  inactiveAt: null;
};

type ClearbitUserFetchError = {
  error: {
    type: 'queued' | 'unknown_record';
    message: string;
  };
};

type ClearbitFlatUser = {
  email: string;
  fullName: string;
  givenName: string;
  familyName: string;
  phone: string;
  employmentDomain: string;
  employmentName: string;
  location: string;
  city: string;
  state: string;
  stateCode: string;
  country: string;
  countryCode: string;
};

type ClearbitCompanyRecord = {
  id: string;
  name: string;
  geo: {
    streetNumber: string;
    streetName: string;
    subPremise: null;
    streetAddress: string;
    city: string;
    postalCode: string;
    state: string;
    stateCode: string;
    country: string;
    countryCode: string;
    lat: number;
    lng: number;
  };
  metrics: {
    alexaUsRank: number;
    alexaGlobalRank: number;
    trafficRank: string;
    employees: number;
    employeesRange: string;
    marketCap: null;
    raised: number;
    annualRevenue: null;
    estimatedAnnualRevenue: string;
    fiscalYearEnd: null;
  };
};

type ClearbitCompanyFetchError = {
  error: {
    type: 'queued' | 'unknown_record';
    message: string;
  };
};

type ClearbitFlatCompany = {
  employees: number;
  country: string;
};

const fetchClearbitUserByEmail = async (
  email: string
): Promise<ClearbitUserRecord | ClearbitUserFetchError> => {
  return await fetchAndParseWithRetry<ClearbitUserRecord>(
    `https://person.clearbit.com/v2/people/find?email=${email}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${CLEARBIT_API_KEY}`,
      },
    }
  );
};

const flattenClearbitUserRecord = (
  user: ClearbitUserRecord
): ClearbitFlatUser => {
  const { email, name, phone, location, geo, employment } = user || {};
  const { fullName, givenName, familyName } = name || {};
  const { city, state, country, stateCode, countryCode } = geo || {};
  const { domain: employmentDomain, name: employmentName } = employment || {};

  // add available attrs to user here
  return {
    email,
    fullName,
    givenName,
    familyName,
    phone,
    employmentDomain,
    employmentName,
    location,
    city,
    state,
    country,
    stateCode,
    countryCode,
  };
};

const isValidClearbitUserRecord = (person: Partial<ClearbitUserRecord>) => {
  // if no email present
  if (!person?.email) {
    return false;
  }
  // if only email present
  if (Object.keys(person).length === 1 && !!person.email) {
    return false;
  }
  // any record with more than the email returned is valuable and valid
  return true;
};

const isValidClearbitUser = (
  result: ClearbitUserRecord | ClearbitUserFetchError
) => {
  return (
    isNonEmptyObject(result) && // test for a valid object
    isValidClearbitUserRecord(result as ClearbitUserRecord) && // and a valid user
    !(result as ClearbitUserFetchError).error // and that its not an error (should not have error attr)
  );
};

const fetchClearbitCompanyByDomain = async (
  domain: string
): Promise<ClearbitCompanyRecord | ClearbitCompanyFetchError> => {
  return await fetchAndParseWithRetry<ClearbitCompanyRecord>(
    `https://company.clearbit.com/v2/companies/find?domain=${domain}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${CLEARBIT_API_KEY}`,
      },
    }
  );
};

const flattenClearbitCompanyRecord = (
  company: ClearbitCompanyRecord
): ClearbitFlatCompany => {
  const { geo, metrics } = company || {};
  const { employees } = metrics || {};
  const { country } = geo || {};

  // add available attrs to user here
  return {
    employees,
    country,
  };
};

const isValidClearbitCompanyRecord = (
  company: Partial<ClearbitCompanyRecord>
) => {
  // if no name present
  if (!company?.name) {
    return false;
  }
  // if only name present
  if (Object.keys(company).length === 1 && !!company.name) {
    return false;
  }
  // any record with more than the name returned is valuable and valid
  return true;
};

const isValidClearbitCompany = (
  result: ClearbitCompanyRecord | ClearbitCompanyFetchError
) => {
  return (
    isNonEmptyObject(result) && // test for a valid object
    isValidClearbitCompanyRecord(result as ClearbitCompanyRecord) && // and a valid user
    !(result as ClearbitCompanyFetchError).error // and that its not an error (should not have error attr)
  );
};

/**
 * WARNING: Serverside use only. For client-side calls, use the proxy endpoint.
 */
// export const fetchClearbitUserData = async (
//   email: string
// ): Promise<ClearbitFlatUser | null> => {
//   try {
//     const result = await fetchClearbitUserByEmail(email);

//     // validate result
//     if (!isValidClearbitUser(result)) {
//       return null;
//     }

//     // we can assume this is a valid Clearbit user record by now
//     return flattenClearbitUserRecord(result as ClearbitUserRecord);
//   } catch (err) {
//     console.error(
//       `Something went wrong fetching Clearbit user: ${err.toString()}`
//     );
//   }
//   return null;
// };

export const fetchUserFormData = async (
  email: string
): Promise<ClearbitFlatUser | null> => {
  const data = await fetchAndParseWithRetry<{ user: ClearbitFlatUser | null }>(
    '/api/www-vercel-user-enrichment',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }
  );
  return data?.user;
};


export const fetchClearbitUserData = async (
  email: string
): Promise<ClearbitFlatUser | null> => {
  const data = await fetchAndParseWithRetry<{ user: ClearbitFlatUser | null }>(
    '/api/www-vercel-user-enrichment',
    {
      method: 'POST',
      headers: {

        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }
  );

  return data?.user || null;
};





/**
 * WARNING: Serverside use only. For client-side calls, use the proxy endpoint.
 */
export const fetchClearbitCompanyData = async (
  employmentDomain: string
): Promise<ClearbitFlatCompany | null> => {
  try {
    const result = await fetchClearbitCompanyByDomain(employmentDomain);

    // validate result
    if (!isValidClearbitCompany(result)) {
      return null;
    }

    // we can assume this is a valid Clearbit company record by now
    return flattenClearbitCompanyRecord(result as ClearbitCompanyRecord);
  } catch (err) {
    console.error(
      `Something went wrong fetching Clearbit company: ${err.toString()}`
    );
  }
  return null;
};

export const fetchCompanyFormData = async (
  employmentDomain: string
): Promise<ClearbitFlatCompany | null> => {
  const data = await fetchAndParseWithRetry<{
    company: ClearbitFlatCompany | null;
  }>('/api/www-vercel-company-lookup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ employmentDomain }),
  });
  return data?.company;
};

import React from 'react';

const GlossaryLeadInText = ( { attributes } ) => {
  let { copy } = attributes;



  return (
    <div className="mb-32">
      <div class="grid sm:max-w-725 w-100%">
        <div class="row">
          <div class="column flex flex-col items-start w-100% pb-32">
            <p class="is-style-lead-in"
              dangerouslySetInnerHTML={{ __html: copy }}>
            </p>
          </div>
          <div className="h-2 bg-[#DDDBD6] w-136"></div>
        </div>
      </div>
    </div>
  );
}

export default GlossaryLeadInText;
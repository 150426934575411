import React, { useEffect, useRef } from 'react';

// https://github.com/vercel/next.js/discussions/17919
// https://stackoverflow.com/questions/49761241/executing-a-script-tag-on-append-after-the-dom-has-loaded
const Html = (props) => {
  const { attributes } = props;
  const { className, innerContent } = attributes;
  const htmlRef = useRef();

  const containsScripts = /<script/.test(innerContent);

  useEffect(() => {
    if (!containsScripts) {
      return;
    }
    const range = document.createRange();
    htmlRef.current.append(range.createContextualFragment(innerContent));
  }, []);

  return (
    <div
      ref={htmlRef}
      className={className}
      dangerouslySetInnerHTML={{
        __html: containsScripts ? '' : innerContent,
      }}
    />
  );
};

export default Html;

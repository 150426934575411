import React from 'react';
import classnames from 'classnames';

const Fields = (data) => {
  let { attributes, children } = data;
  let { className } = attributes;

  return (
    <div className={`fields ${classnames({ [className]: className })}`}>
      {children}
    </div>
  );
};

export default Fields;

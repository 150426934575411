import React, { useState, useEffect, useRef } from 'react';
import amplitude from 'amplitude-js';
import Cookies from 'universal-cookie';
import { recursiveMap, getIncrementValue } from '../helpers';
import { getXDayFromNow } from '../../helpers/date-helpers';

const Toggler = ({ children, attributes }) => {
  let { className, hideInitial, initialIndex, onMount, autoPlayTime } =
    attributes || {};

  let [activeIndex, setActiveIndex] = useState(
    initialIndex || (hideInitial ? null : 0)
  );
  let [isVisible, setIsVisible] = useState(false);

  let triggerIndex = null,
    targetIndex = null,
    numOfItems = useRef(undefined),
    videos = useRef([]),
    animations = useRef([]),
    timer = useRef(undefined),
    togglerRef = useRef(null);

  const handleIntersect = (event) => {
    if (event[0].isIntersecting) {
      if (activeIndex === null) {
        setActiveIndex(0);
      }
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    let observer;

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 1],
    };

    observer = new IntersectionObserver(handleIntersect, options);
    togglerRef.current !== null ? observer.observe(togglerRef.current) : null;

    if (onMount) {
      var onMountFnc = new Function('values', onMount);
      const cookies = new Cookies();

      onMountFnc({ setActiveIndex, cookies, amplitude, getXDayFromNow });
    }
    return () =>
      togglerRef.current !== null
        ? observer.unobserve(togglerRef.current)
        : null;
  }, []);

  useEffect(() => {
    if (autoPlayTime) {
      clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        if (isVisible) {
          const newActiveIndex = getIncrementValue({
            activeIndex,
            increment: 1,
            numOfItems: numOfItems.current,
          });
          setActiveIndex(newActiveIndex);
        }
      }, autoPlayTime);
    }
  }, [activeIndex, isVisible]);

  if (numOfItems.current === undefined) {
    numOfItems.current = 0;

    recursiveMap(children, (child, i) => {
      // @TODO: May no longer be necesarry. Stray targets aren't used to pass
      // data-active... since new selector allows descendents to be targeted
      if (child.props.attributes?.alias === 'Targets') {
        numOfItems.current = 0;
      }

      switch (child.props.name) {
        case 'target':
          if (child.props.attributes.alias) {
            break;
          }
          numOfItems.current++;
          break;
        case 'video':
          if (child.props.attributes) {
            videos.current.push(child.props.attributes.duration);
          }
        case 'animation':
          if (child.props.attributes) {
            animations.current.push(child.props.attributes.duration);
          }
      }

      if (React.isValidElement(child)) {
        return React.cloneElement(child, {});
      }
      return child;
    });
  }

  const childrenWithProps = recursiveMap(children, (child, i) => {
    switch (child.props.name) {
      case 'trigger':
        triggerIndex = i;

        var props = {
          ...child.props,
          activeIndex,
          index: i,
          setActiveIndex,
          numOfItems: numOfItems.current,
        };
        break;
      case 'target':
        targetIndex = i;

        var props = {
          ...child.props,
          activeIndex,
          index: i,
          numOfItems: numOfItems.current,
        };
        break;
      case 'video':
        var props = {
          ...child.props,
          isToggled: true,
          isActive: targetIndex === activeIndex,
          onEnded: () => {
            let newActiveIndex =
              activeIndex + 1 === numOfItems.current ? 0 : activeIndex + 1;
            setActiveIndex(newActiveIndex);
          },
        };
        break;
      case 'animation':
        const options = child.props.attributes.opts;

        var props = {
          ...child.props,
          isToggled: true,
          isActive: targetIndex === activeIndex,
          activeIndex,
          // @TODO address

          attributes: {
            ...child.props.attributes,
            opts: {
              ...options,
              loop: options && options.loop === false ? false : true,
            },
          },
          onComplete: (activeIndex) => {
            let newActiveIndex =
              activeIndex + 1 === numOfItems.current ? 0 : activeIndex + 1;
            options && options.autoRotate === true
              ? setActiveIndex(newActiveIndex)
              : '';
          },
        };
        break;
      case 'html':
        if (/timer/.test(child.props.attributes.className)) {
          var props = {
            ...child.props,
            attributes: {
              ...child.props.attributes,
              originalStyles: {
                animationDuration: `${videos.current[triggerIndex]}s`,
              },
            },
          };
        }
        break;
      case 'form-2':
        targetIndex = i;

        var props = {
          ...child.props,
          attributes: {
            ...child.props.attributes,
            customOnSuccess: (e) => setActiveIndex(activeIndex + 1),
          },
        };
        break;
      case 'select':
        targetIndex = i;

        var props = {
          ...child.props,
          attributes: {
            ...child.props.attributes,
            onSelectChange: (e) =>
              new Function('values', child.props.attributes.onSelectChange)({
                e,
                activeIndex,
                setActiveIndex,
              }),
          },
        };
        break;
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <div ref={togglerRef} className={`${className}`} data-toggler>
      {childrenWithProps}
    </div>
  );
};

export default Toggler;

import React, { useState, useRef } from 'react';

const Field2 = (props) => {
  const [isError, setIsError] = useState(false);
  const {
    attributes,
    handleBlur,
    handleChange,
    setValues,
    values,
    errors,
    touched,
  } = props;
  const {
    className,
    innerClassName,
    name,
    type,
    label,
    placeholder,
    isRequired,
    dependency,
    isDependentField,
    preText,
    showError,
    rows,
    maxCharacters,
  } = attributes;

  const Wrapper = label ? 'label' : 'div';

  const theField = useRef(null);

  const onBlur = (e) => {
    const val = e.target.value;

    if (name) {
      if (handleBlur) {
        handleBlur(val);
      }
    }
  };

  const onChange = (e) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;

    if (isError && emailRegex.test(e.target.value)) {
      setIsError(false);
    }

    // eslint-disable-next-line no-undef
    let val = type === 'tel' ? normalizeInput(e.target.value) : e.target.value;
    val =
      type === 'number' && name === 'user_employees' ? val.slice(0, 4) : val;

    if (name) {
      setValues(Object.assign(values, { [name]: val }));
      if (handleChange) {
        handleChange(val);
      }
    }
  };

  return (
    <Wrapper className={`field-2 ${className}`}>
      {preText && <p className="field_pretext">{preText}</p>}
      <input
        className={`${label ? '' : 'field '}${innerClassName}${
          errors[name] && touched[name] ? ' -error' : ''
        } ${isError ? '-error' : ''}`}
        name={name}
        ref={theField}
        // as={as}
        type={type || 'text'}
        rows={rows}
        maxLength={maxCharacters}
        data-dependency={dependency}
        placeholder={placeholder}
        required={isRequired}
        onBlur={onBlur}
        onChange={onChange}
        data-simple-field
      />
      {label && <span className="field_label">{label}</span>}
      {showError && (
        // eslint-disable-next-line no-undef
        <ErrorMessage
          name={name}
          className="form_errorMessage"
          component="div"
        />
      )}
    </Wrapper>
  );
};

export default Field2;

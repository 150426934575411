import React from 'react';
import { reformatAnchorText } from '../helpers';

const Heading = (props) => {
  let { attributes } = props;
  let { anchorData, className, data, innerContent, level } = attributes;

  const anchorRegex = /<a[^>]*?>[^<]*?<\/a>/;

  level = level || 2;

  const H = `h${level}`;

  return anchorRegex.test(innerContent) ? (
    <H className={className} {...data} {...anchorData}>
      {reformatAnchorText(innerContent)}
    </H>
  ) : (
    innerContent && (
      <H
        className={className}
        dangerouslySetInnerHTML={{ __html: innerContent }}
        {...data}
        {...anchorData}
      />
    )
  );
};

export default Heading;

import React from 'react';
import { recursiveMap } from '../helpers';

const Target = (props) => {
  let { attributes, activeIndex, index, numOfItems, children } = props;
  let { className, explicitValue, dataFunction, contentFunction } = attributes;

  const defaultFunction = `return {'data-active': activeIndex === (explicitValue || index) || undefined}`;

  dataFunction = new Function(
    'activeIndex',
    'index',
    'numOfItems',
    'explicitValue',
    dataFunction || defaultFunction
  );
  contentFunction = contentFunction
    ? new Function(
        'activeIndex',
        'index',
        'numOfItems',
        'explicitValue',
        contentFunction
      )
    : null;

  var data = dataFunction(activeIndex, index, numOfItems, explicitValue);

  const childrenWithProps = contentFunction
    ? contentFunction(activeIndex, index, numOfItems)
    : recursiveMap(children, (child, i) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            attributes: {
              ...child.props.attributes,
              data: {
                ...child.props.attributes?.data,
                'data-active': activeIndex === index || undefined,
              },
            },
          });
        }
        return child;
      });

  return (
    <div className={`${className}`} {...data} data-target>
      {childrenWithProps}
    </div>
  );
};

export default Target;

import React from 'react';

const Submit = (props) => {
  let { attributes } = props;
  let { className, data, value } = attributes;

  return (
    <div className={className}>
      <input
        className="button -primary -large -full"
        type="submit"
        value={value}
        {...data}
      />
    </div>
  );
};

export default Submit;

import React from 'react';
import Link from '../../templates/partials/LinkHelper';
import Image from '../basic/Image';

const GlossaryInlineTipCta = (props) => {
  const { attributes } = props;
  let {
    iconUrl,
    headline,
    subcopy,
    buttonText,
    buttonUrl
  } = attributes;


  iconUrl = iconUrl ? iconUrl : 'https://rippling.imgix.net/images/icon-info.svg';
  headline = headline ? headline : 'Schedule a demo with Rippling today'
  buttonText = buttonText ? buttonText : 'Learn more';
  buttonUrl = buttonUrl ? buttonUrl : '/request-demo';
  return (
    <div className="wrapper grid max-w-100% pb-24 md:pb-48 pt-24 md:pt-48 w-100% md:max-w-725 ">
         <Link
            href={buttonUrl}
            className="block wrapper group line-card bg-eggshell max-w-none mx-auto pl-24 pr-24 py-32 relative rounded-2 z-1000 sm:max-w-750 md:pl-48 md:pr-48 md:w-100% no-underline"
        >
            <div className="wrapper mb-32 h-48  w-48">
                <Image
                    alt=""
                    attributes={{ mediaURL: iconUrl, width: 48, height: 48 }}
                />
            </div>
            <p className="-fw-700 leading-28 mb-8 text-22 text-charcoal sm:leading-34 sm:text-24">
                <strong>{headline}</strong>
            </p>
            <p className="leading-32 mb-24 opacity-100 text-20 text-charcoal text-grey4 tracking-0 sm:mb-32 sm:opacity-70">
                {subcopy}
            </p>
            <p className="-hide-links font-medium line-draw after:bg-eggshell inline-block leading-24 pb-2 shrink-0 text-16 text-plum sm:pb-4">
                {buttonText}
            </p>
        </Link>
    </div>
  );
}

export default GlossaryInlineTipCta;
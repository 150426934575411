import React, { useEffect, useState } from 'react';
import { ErrorMessage } from 'formik';
import loadable from '@loadable/component';
import { registerLocale } from 'react-datepicker';

import amplitude from 'amplitude-js';

let DatePicker;
let setHours;
let setMinutes;

// Check in node-modules/date-fns/locale folder to see if we need to overwrite
const localeToDateFnsLocale = {
  'de-DE': 'de',
  'fr-FR': 'fr',
};

export const loadDateFnsLocale = async (locale) => {
  return await import(
    `date-fns/locale/${localeToDateFnsLocale[locale] ?? locale}/index.js`
  );
};

const getPlaceholderText = (locale) => {
  if (locale.startsWith('fr')) {
    return 'Date et heure';
  }

  return 'Date and time';
};

const addDays = (startDate, noOfDaysToAdd) => {
  let endDate;
  let count = 0;

  while (count < noOfDaysToAdd) {
    endDate = new Date(startDate.setDate(startDate.getDate() + 1));
    if (endDate.getDay() != 0 && endDate.getDay() != 6) {
      count++;
    }
  }

  return endDate;
};

const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const customFilter = (curDate, datesToRemove) => {
  datesToRemove = datesToRemove?.split(',');

  return !datesToRemove?.some((dateToRemove) => {
    let [year, month, date] = dateToRemove.split('-');
    return (
      curDate.getDate() === parseInt(date) &&
      curDate.getMonth() + 1 === parseInt(month) &&
      curDate.getFullYear() === parseInt(year)
    );
  });
};

const DatePickerComponent = (props) => {
  const { attributes, errors, locale, touched, setValues, values } = props;
  const {
    className,
    innerClassName,
    name,
    maxDate,
    minDate,
    datesToRemove,
    isInline,
  } = attributes;

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    setValues(Object.assign(values, { [name]: '' }));

    DatePicker = DatePicker ?? loadable(() => import('react-datepicker'));
    setHours = setHours ?? require('date-fns/setHours').default;
    setMinutes = setMinutes ?? require('date-fns/setMinutes').default;
    // import(`react-datepicker/dist/react-datepicker.css`)

    loadDateFnsLocale(locale).then((data) => {
      if (!data) {
        return;
      }

      // Inject localeData during runtime
      window.__localeData__ = window.__localeData__ ?? {};
      window.__localeData__[locale] = data;
      registerLocale(locale, data.default);
    });
  }, [locale]);

  return (
    <div className={`reactDatePicker ${className}`}>
      {DatePicker && (
        <DatePicker
          name={name}
          className={`field ${innerClassName}${
            errors[name] && touched[name] ? ' -error' : ''
          }`}
          showTimeSelect
          inline={isInline || undefined}
          selected={startDate}
          dateFormat="PP p"
          locale={locale}
          placeholderText={getPlaceholderText(locale)}
          // showDisabledMonthNavigation
          autocomplete="off"
          filterDate={(date) =>
            isWeekday(date) && customFilter(date, datesToRemove)
          }
          minDate={minDate ? addDays(new Date(), parseInt(minDate)) : undefined}
          maxDate={maxDate ? addDays(new Date(), parseInt(maxDate)) : undefined}
          minTime={
            setMinutes ? setHours(setMinutes(new Date(), 0), 9) : undefined
          }
          maxTime={
            setHours ? setHours(setMinutes(new Date(), 0), 17) : undefined
          }
          onChangeRaw={(event) => {
            if (
              event.target &&
              event.target.classList.contains('react-datepicker__day')
            ) {
              amplitude.getInstance().logEvent(`Click - v3 Date Selector`);

              window.dataLayer.push({
                event: 'date_selected',
              });
            }
          }}
          onChange={(val) => {
            if (
              (!values.schedule_demo_datetime && val && val.getHours() !== 0) ||
              (values.schedule_demo_datetime &&
                values.schedule_demo_datetime.getHours() === 0 &&
                val.getHours() !== 0) ||
              (values.schedule_demo_datetime &&
                Math.abs(
                  values.schedule_demo_datetime.getTime() - val.getTime()
                ) <= 28800000)
            ) {
              amplitude.getInstance().logEvent(`Click - v3 Time Selector`);

              window.dataLayer.push({
                event: 'time_selected',
              });
            }

            if (minDate) {
              const theMinDate = addDays(new Date(), parseInt(minDate));
              theMinDate.setHours(0);
              theMinDate.setMinutes(0);

              if (theMinDate.getTime() > val.getTime()) {
                theMinDate.setHours(val.getHours());
                theMinDate.setMinutes(val.getMinutes());
                val = theMinDate;
              }
            }

            setValues(Object.assign(values, { [name]: val }));
            setStartDate(val);
          }}
          onFocus={() => {
            if (name === 'schedule_demo_datetime') {
              amplitude.getInstance().logEvent(`Click - Date / Time Selector`);
            }
          }}
        />
      )}

      {
        <ErrorMessage
          name={name}
          className="form_errorMessage"
          component="div"
        />
      }
    </div>
  );
};

export default DatePickerComponent;

import React, { useLayoutEffect } from 'react';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import { useRouter } from 'next/router';
import { gsap, ScrollTrigger } from 'gsap/dist/all';

const Gsap = (props) => {
  const router = useRouter();
  const { attributes, children } = props;
  let { className, data, onMount } = attributes;

  gsap.registerPlugin(ScrollTrigger);

  const fullClassName = `${className} ${classnames()}`;
  className = `${fullClassName}`;

  useLayoutEffect(() => {
    if (onMount) {
      const cookies = new Cookies();
      const context = gsap.context(() => {
        try {
          !window?.gsap ? (window.gsap = gsap) : null;
          !window?.ScrollTrigger
            ? (window.ScrollTrigger = ScrollTrigger)
            : null;
          new Function(onMount)({ cookies, router });
        } catch (e) {
          console.error(e);
        }
      });

      return () => context.revert();
    }
  }, [onMount]);

  return (
    <div className={className} {...data}>
      {children}
    </div>
  );
};

export default Gsap;

import React, { useState } from 'react';
import Image from 'components/basic/Image';
import SectionBuilder from ':templates/SectionBuilder';
import { getObjectEntries } from ':helpers/object-helpers';
import { isObject } from ':helpers/validation-helpers';

const enableCalculateButton = (stateVals, renderedValues, hasRegions) => {
  const allValuesProperlyFilled =
    stateVals.selectedCountry &&
    (hasRegions ? stateVals.selectedRegion : true) &&
    stateVals.selectedAnnualIncome >= 0;

  const valuesAreDifferent =
    stateVals.selectedCountry !== renderedValues.selectedCountry ||
    (hasRegions
      ? stateVals.selectedRegion !== renderedValues.selectedRegion
      : true) ||
    stateVals.selectedAnnualIncome !== renderedValues.selectedAnnualIncome;

  return allValuesProperlyFilled && valuesAreDifferent;
};

const slugToTitle = (str) => str.replace(/_/g, ' ');

const numberWithCommas = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatCost = (num, formIsComplete, isAnnual) => {
  const adjustedNum = isAnnual ? num : num / 12;

  return !formIsComplete ? '-' : numberWithCommas(adjustedNum.toFixed(2));
};

const getLineItemCost = ({ percentage, maximum, selectedAnnualIncome }) => {
  const decimal = parseFloat(percentage) / 100;
  const totalCost = decimal * selectedAnnualIncome;
  const max = parseFloat(maximum);
  return max !== null && totalCost > max ? max : totalCost;
};

const getDashClass = (num) => (!num ? 'text-black/50 inline-block' : '');

const Caret = ({ color }) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.53033e-08 0.685191L0.708875 -5.11514e-08L6 5.56874L11.2911 7.50407e-08L12 0.685191L6 7L7.53033e-08 0.685191Z"
      fill={color}
    />
  </svg>
);

const Flags = {
  Australia: 'https://rippling.imgix.net/images/australia.png?fm=png',
  Brazil: 'https://rippling.imgix.net/images/brazil.png?fm=png',
  Canada: 'https://rippling.imgix.net/images/canada.png?fm=png',
  China: 'https://rippling.imgix.net/images/china.svg',
  France: 'https://rippling.imgix.net/images/france-1.png?fm=png',
  Germany: 'https://rippling.imgix.net/images/germany.png?fm=png',
  India: 'https://rippling.imgix.net/images/india.png?fm=png',
  Ireland: 'https://rippling.imgix.net/images/ireland.png?fm=png',
  Japan: 'https://rippling.imgix.net/images/japan.svg',
  Mexico: 'https://rippling.imgix.net/images/mexico.svg',
  Netherlands: 'https://rippling.imgix.net/images/netherlands.png?fm=png',
  Poland: 'https://rippling.imgix.net/images/poland.png?fm=png',
  Portugal: 'https://rippling.imgix.net/images/portugal.svg',
  Singapore: 'https://rippling.imgix.net/images/singapore.svg',
  Spain: 'https://rippling.imgix.net/images/kEp43M7o-spain.svg',
  Sweden: 'https://rippling.imgix.net/images/sweden.svg',
  'United Kingdom':
    'https://rippling.imgix.net/images/united-kingdom.png?fm=png',
  'United States': 'https://rippling.imgix.net/images/united-states.png?fm=png',
};

const isLineItem = (obj) => {
  return (
    isObject(obj) &&
    Object.values(obj).every((item) => Object.keys(item).includes('percentage'))
  );
};

const getUniqueCountryCurrencies = (data) => {
  const result = {};
  const currencies = {};

  Object.keys(data).filter((key) => {
    const currency = data[key].meta.abbreviation;

    if (!currencies[currency]) {
      result[key] = data[key];
    }

    currencies[currency] = true;
  });

  return result;
};

const EmployeeCostCalculator = (props) => {
  const { attributes } = props;
  const { data: rawData, form, isGated } = attributes;

  const data = { ...rawData };
  const uniqueCountryCurrencies = getUniqueCountryCurrencies(rawData);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState('-');

  const [showCalculator, setShowCalculator] = useState(true);
  const [formIsComplete, setFormIsComplete] = useState(false);

  const [renderedValues, setRenderedValues] = useState({
    selectedAnnualIncome: 0,
    annualEmployerCosts: 0,
    totalAnnualEmployerCosts: 0,
    currencySign: '$',
    selectedCurrency: {
      abbreviation: 'USD',
    },
  });

  const [selectedCurrency, setSelectedCurrency] = useState(
    rawData.Australia.meta
  );

  const [isAnnual, setIsAnnual] = useState(true);
  const [showLineItems, setShowLineItems] = useState(false);

  const currencySign = selectedCurrency?.symbol || '$';
  const countries = Object.keys(data).sort((a, b) => a - b);

  let regions = [];
  let lineItems = [];
  const hasRegions = !isLineItem(data[selectedCountry]?.rates);

  if (data[selectedCountry]?.rates) {
    if (hasRegions) {
      regions = getObjectEntries(data[selectedCountry].rates);
      lineItems =
        getObjectEntries(data[selectedCountry].rates[selectedRegion]) || [];
    } else {
      lineItems = getObjectEntries(data[selectedCountry].rates);
    }
  }

  form[0].attributes = {
    ...form[0].attributes,
    customOnSuccess: () => {
      setShowCalculator(true);
      setFormIsComplete(true);
    },
  };

  const annualEmployerCosts = lineItems.reduce(
    (accum, [_, { percentage, maximum }]) =>
      accum +
      getLineItemCost({
        percentage,
        maximum,
        selectedAnnualIncome,
      }),
    0
  );

  const totalAnnualEmployerCosts = annualEmployerCosts + selectedAnnualIncome;

  const lineItemsToRender = renderedValues.lineItems || lineItems;

  return (
    <div className="overflow-hidden">
      <div className="grid static z-1000 min-h-screen ">
        <div className="w-100% static md:display-grid md:grid-cols-2 md:gap-200 min-h-screen  md:pt-80 pt-72">
          <div className="md:py-160 py-48 sm:pb-80 ">
            <div className="mb-48">
              <h1 className="mb-16">Employee cost calculator</h1>
              <p className="is-style-p1 text-black/70">
                Have somewhere great in mind for your next hire? Estimate the
                cost of employment in different countries.
              </p>
            </div>

            <div className="-bg-white w-100%">
              <div className="mb-16">
                <label
                  htmlFor=""
                  className="field-2 block is-style-p1 !text-black mb-12"
                >
                  <span class="field_label">Country of residence</span>
                  <select
                    name=""
                    id=""
                    className={`bg-transparent ${
                      selectedCountry ? '!text-black' : ''
                    }`}
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      data[e.target.value]?.meta
                        ? setSelectedCurrency(data[e.target.value].meta)
                        : rawData.Australia.meta;
                      setSelectedRegion(null);
                      !regions?.length && setShowLineItems(true);
                    }}
                  >
                    <option value="" className="">
                      Select a country
                    </option>
                    {countries.map((country) => (
                      <option value={country} className="">
                        {slugToTitle(country)}
                      </option>
                    ))}
                  </select>
                  <div className="absolute top-50% -translate-y-50% right-16">
                    <Caret color={'#000'} />
                  </div>
                  <div className="w-20 h-20 absolute top-50% -translate-y-50% right-16">
                    {Flags[selectedCountry] ? (
                      <Image
                        attributes={{
                          mediaURL: Flags[selectedCountry],
                        }}
                      />
                    ) : null}
                  </div>
                </label>
              </div>
              {!!regions?.length && (
                <div className="mb-16">
                  <label
                    htmlFor=""
                    className="field-2 block is-style-p1 !text-black mb-12"
                  >
                    <span class="field_label">
                      {selectedCountry === 'Netherlands'
                        ? 'Contract Duration'
                        : 'Province/Region'}
                    </span>
                    <select
                      name=""
                      id=""
                      className={`bg-transparent ${
                        selectedRegion ? '!text-black' : ''
                      }`}
                      value={selectedRegion}
                      onChange={(e) => {
                        setSelectedRegion(e.target.value);
                        setShowLineItems(true);
                      }}
                    >
                      <option value="" className="">
                        {selectedCountry === 'Netherlands'
                          ? 'Select a contract'
                          : 'Select a region'}
                      </option>
                      {regions.map(([region, _]) => (
                        <option value={region} className="">
                          {slugToTitle(region)}
                        </option>
                      ))}
                    </select>
                    <div className="absolute top-50% -translate-y-50% right-16">
                      <Caret color={'#000'} />
                    </div>
                  </label>
                </div>
              )}

              <div className="mb-24">
                <label
                  htmlFor=""
                  className="field-2 block is-style-p1 text-black/70 mb-12"
                >
                  <span class="field_label">Gross annual income</span>
                  <input
                    style={{ outline: 'none' }}
                    type="text"
                    className={`w-100% bg-transparent pt-0 ${
                      typeof selectedAnnualIncome === 'string'
                        ? '!text-black/50'
                        : ''
                    }`}
                    value={numberWithCommas(selectedAnnualIncome)}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value.replace(/,/g, ''));

                      if (typeof val !== 'number') {
                        return '';
                      }

                      setSelectedAnnualIncome(Math.abs(parseFloat(val || 0)));
                    }}
                  />

                  <div className="absolute right-16 top-50% -translate-y-50%">
                    <p className="is-style-p2 text-black/50">
                      {selectedCurrency.abbreviation}
                    </p>
                  </div>
                </label>
              </div>

              <input
                type="submit"
                value="Calculate"
                className={`p-16 w-100% ${!formIsComplete && !showCalculator}`}
                disabled={
                  (!formIsComplete && !showCalculator) ||
                  !enableCalculateButton(
                    { selectedAnnualIncome, selectedCountry, selectedRegion },
                    renderedValues,
                    hasRegions
                  )
                }
                onClick={() => {
                  if (!formIsComplete) {
                    if (isGated) {
                      setShowCalculator(false);
                    } else {
                      setFormIsComplete(true);
                    }
                  }

                  setRenderedValues({
                    selectedAnnualIncome,
                    annualEmployerCosts,
                    totalAnnualEmployerCosts,
                    lineItems,
                    selectedCountry,
                    selectedRegion,
                    currencySign,
                    selectedCurrency,
                  });

                  if (
                    selectedAnnualIncome &&
                    selectedCountry &&
                    selectedRegion
                  ) {
                    window.dataLayer.push({
                      event: 'rippling_employee_calc_submit',
                      selected_annual_income: selectedAnnualIncome,
                      selected_country: selectedCountry,
                      selected_region: selectedRegion,
                    });
                  } else {
                    window.dataLayer.push({
                      event: 'rippling_employee_calc_submit',
                      selected_annual_income: selectedAnnualIncome,
                      selected_country: selectedCountry,
                    });
                  }
                }}
              />
            </div>
            <div className="mt-32 pt-32 border-t-1 border-tertiary-gray2 flex flex-col gap-16"> 
              <p className="is-style-p2 font-medium text-tertiary-gray4">Disclaimer</p>
              <p className="is-style-p2 text-tertiary-gray4">Employee costs are an estimate and may be subject to changes based on updates to local tax legislations and regimes.</p>
            </div>
          </div>

          <div className="md:static md:pt-92 pt-48 sm:pt-80 ">
            <div className="absolute top-0 -left-50% w-200% h-100% bg-secondary-eggshell md:hidden"></div>
            <div className="absolute top-0 md:w-50vw h-full bg-secondary-eggshell md:right-0 smx:hidden"></div>
            <div className="ext-stackedFadeIns h-100%">
              <div
                className="flex flex-col justify-between"
                // data-active={showCalculator || undefined}
                data-active={true}
                style={
                  !showCalculator && !formIsComplete
                    ? { filter: 'blur(20px)' }
                    : {}
                }
              >
                <div className="w-100% mb-48">
                  <div
                    className="bg-white p-6 flex rounded-4 mb-48"
                    style={{ boxShadow: '2px 8px 12px 0 rgba(0, 0, 0, 0.08)' }}
                  >
                    <div
                      className="w-50% bg-secondary-eggshell absolute h-100% rounded-3 transition-all duration-200"
                      style={{
                        left: isAnnual ? 'calc(0% + 6px)' : 'calc(50%)',
                        width: 'calc(50% - 6px)',
                        height: 'calc(100% - 12px)',
                      }}
                    ></div>
                    <div
                      className="w-50% text-center p-8 cursor-pointer"
                      onClick={() => setIsAnnual(true)}
                    >
                      <p
                        className={`is-style-p1 font-medium ${
                          !isAnnual ? 'text-plum/70' : 'text-plum'
                        }`}
                      >
                        Annual
                      </p>
                    </div>
                    <div
                      className="w-50% text-center p-8 cursor-pointer"
                      onClick={() => setIsAnnual(false)}
                    >
                      <p
                        className={`is-style-p1 font-medium ${
                          isAnnual ? 'text-plum/70' : 'text-plum'
                        }`}
                      >
                        Monthly
                      </p>
                    </div>
                  </div>

                  <div className="pb-24 border-b-1 border-tertiary-gray3 flex  flex-col sm:flex-row sm:items-center justify-between ">
                    <p className="text-black/70 is-style-p1 font-medium mr-8">
                      Gross {isAnnual ? 'Annual' : 'Monthly'} Salary
                    </p>
                    <h2 className="flex shrink-0">
                      <span className="mr-4">
                        {renderedValues.currencySign}
                      </span>
                      <span
                        className={getDashClass(
                          renderedValues.selectedAnnualIncome
                        )}
                      >
                        {formatCost(
                          renderedValues.selectedAnnualIncome,
                          formIsComplete,
                          isAnnual
                        )}
                      </span>
                    </h2>
                  </div>
                  <div className="pt-24 mb-48">
                    <div className="flex justify-between flex-col sm:flex-row sm:items-center">
                      <h4 className="text-black/70 is-style-p1 font-medium mr-8">
                        {isAnnual ? 'Annual' : 'Monthly'} Employer Cost
                      </h4>
                      <h2 className="flex shrink-0">
                        <span className="mr-4">
                          {renderedValues.currencySign}
                        </span>
                        <span
                          className={getDashClass(
                            renderedValues.annualEmployerCosts
                          )}
                        >
                          {formatCost(
                            renderedValues.annualEmployerCosts,
                            formIsComplete,
                            isAnnual
                          )}
                        </span>
                      </h2>
                    </div>
                    {lineItemsToRender.length ? (
                      <div
                        className={`duration-200 max-h-0 transition-margin-top mt-0 overflow-hidden ${
                          showLineItems ? 'active:max-h-1950 active:mt-8' : ''
                        }`}
                      >
                        <div className="p-24 bg-white rounded-4 mt-24">
                          {lineItemsToRender.map(
                            ([itemName, { percentage, maximum }], i) => {
                              const result = getLineItemCost({
                                percentage,
                                maximum,
                                selectedAnnualIncome:
                                  renderedValues.selectedAnnualIncome,
                              });

                              return (
                                <div
                                  className={`flex justify-between flex-col sm:flex-row sm:items-center border-black border-opacity-15 ${
                                    lineItemsToRender.length - 1 > i
                                      ? 'pb-16 mb-16 border-b-1'
                                      : ''
                                  }`}
                                >
                                  <p className="is-style-p1 text-black/70">
                                    {slugToTitle(itemName)}
                                  </p>
                                  <p className="is-style-p1 font-semibold flex shrink-0">
                                    <div className="mr-2">
                                      {renderedValues.currencySign}
                                    </div>
                                    <span className={getDashClass(result)}>
                                      {formatCost(
                                        result,
                                        formIsComplete,
                                        isAnnual
                                      )}
                                    </span>
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-100% h-100% flex flex-col justify-between gap-24 md:gap-48">
                  <div>
                    <h4 className="mb-8 text-black/70">
                      Total {isAnnual ? 'Annual' : 'Monthly'} Employer Costs
                    </h4>
                    <h1 className="h1-xl mb-24 shrink-0">
                      <span className="mr-12">
                        {renderedValues.currencySign}
                      </span>
                      <span
                        className={getDashClass(
                          renderedValues.totalAnnualEmployerCosts
                        )}
                      >
                        {formatCost(
                          renderedValues.totalAnnualEmployerCosts,
                          formIsComplete,
                          isAnnual
                        )}
                      </span>
                    </h1>

                    {/* <div className="mb-48">
                      <p className="is-style-p1 font-medium flex">
                        <span>Show totals in&nbsp;</span>
                        <div className="cursor-pointer flex text-blue">
                          <span className="mr-4">
                            {renderedValues.selectedCurrency.abbreviation}
                          </span>
                          <select
                            className="cursor-pointer h-100% p-0 opacity-0 absolute top-0 flex z-1000"
                            value={selectedCurrency.abbreviation}
                            onChange={e => {
                              setSelectedCurrency(rawData[e.target.value].meta)
                              setRenderedValues({
                                selectedAnnualIncome:
                                  renderedValues.selectedAnnualIncome,
                                annualEmployerCosts:
                                  renderedValues.annualEmployerCosts,
                                totalAnnualEmployerCosts:
                                  renderedValues.totalAnnualEmployerCosts,
                                lineItems: renderedValues.lineItems,
                                selectedCountry: renderedValues.selectedCountry,
                                selectedRegion: renderedValues.selectedRegion,
                                currencySign:
                                  rawData[e.target.value].meta?.symbol,
                                selectedCurrency: rawData[e.target.value].meta,
                              })
                            }}
                          >
                            <option hidden>Choose</option>
                            {Object.keys(uniqueCountryCurrencies).map(key => {
                              return (
                                <option
                                  className="w-1/4 cursor-pointer"
                                  value={key}
                                >
                                  {rawData[key].meta.abbreviation}
                                </option>
                              )
                            })}
                          </select>
                          <div className="flex items-center">
                            <Caret color={'#5074B1'} />
                          </div>
                        </div>
                      </p>
                    </div> */}
                  </div>

                  {renderedValues.selectedCountry ? (
                    <a
                      href={
                        data[renderedValues.selectedCountry]?.meta
                          .countrypediaLink
                      }
                      target="_blank"
                      className="bg-secondary-dark-eggshell flex items-center justify-between px-24 py-20 rounded-t-4 flex"
                    >
                      <div className="flex items-center">
                        <span>
                          <div className="w-24 h-24 mr-12">
                            <Image
                              attributes={{
                                mediaURL:
                                  Flags[renderedValues.selectedCountry] ||
                                  Flags['United States'],
                              }}
                            />
                          </div>
                        </span>
                        <p className="is-style-p2 font-medium">
                          Want to learn more about hiring in{' '}
                          <span className="text-blue">
                            {renderedValues.selectedCountry}
                          </span>
                          ?
                        </p>
                      </div>

                      <div className="">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.4467 1.98956L14.4571 7.99997L8.4467 14.0104L7.73959 13.3033L12.5429 8.49997H1.5V7.49997H12.5429L7.73959 2.69667L8.4467 1.98956Z"
                            fill="#502D3C"
                          />
                        </svg>
                      </div>
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div
                className={`pt-80 md:pt-24 items-center flex max-h-1950 duration-200 transition-all ${
                  !showCalculator ? 'z-1000' : ''
                }`}
                data-active={!showCalculator || undefined}
              >
                <SectionBuilder blocks={form} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCostCalculator;

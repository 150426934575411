import React from 'react';
import Cookies from 'universal-cookie';
import amplitude from 'amplitude-js';
import { recursiveMap, getIncrementValue } from '../helpers';
import { getXDayFromNow } from '../../helpers/date-helpers';

const Trigger = ({
  attributes,
  activeIndex,
  activeProp,
  setActiveIndex,
  numOfItems,
  index,
  children,
}) => {
  const { className, triggerEvents, styles } = attributes || {};
  let triggerVal;
  const eventsData = {};

  triggerEvents.forEach(
    ({ name, explicitValue, customValFnc, isToggle, increment, callback }) => {
      explicitValue =
        typeof explicitValue === 'number'
          ? explicitValue.toString()
          : explicitValue;

      triggerVal = index;

      if (increment) {
        triggerVal = getIncrementValue({ activeIndex, increment, numOfItems });
      } else if (explicitValue !== undefined) {
        triggerVal = explicitValue;
      } else if (customValFnc) {
        triggerVal = new Function('values', customValFnc)({ activeIndex });
      }

      let toggledVal = triggerVal;

      if (isToggle && activeIndex === (explicitValue || index)) {
        toggledVal = null;
      }

      eventsData[name] = () => {
        setActiveIndex(toggledVal);

        if (callback) {
          const callbackFnc = new Function('values', callback);
          const cookies = new Cookies();

          callbackFnc({ cookies, getXDayFromNow, amplitude });
        }
      };
    }
  );

  const childrenWithProps = recursiveMap(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        attributes: {
          ...child.props.attributes,
          data: {
            ...child.props.attributes?.data,
            [activeProp || 'data-active']:
              activeIndex === triggerVal || undefined,
          },
        },
      });
    }
    return child;
  });

  const activePropObj = {
    [activeProp || 'data-active']: activeIndex === triggerVal || undefined,
  };

  return (
    <div
      className={`${className}`}
      style={styles}
      {...eventsData}
      {...activePropObj}
      data-trigger
    >
      {childrenWithProps}
    </div>
  );
};

export default Trigger;
